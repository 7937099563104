import React, { useState, useEffect } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import Navbar1 from "./Navbar";
import { Link } from "react-router-dom";

import ImageGallery1 from "../assets/home/parrucchiere-artparis.png";
import ImageGallery2 from "../assets/home/logo-starblack.png";
import ImageGallery3 from "../assets/home/commercialista.png";
import ImageGallery4 from "../assets/home/LOGO BEATRICE.png";

const Home = () => {
  const [hoveredItem, setHoveredItem] = useState(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("is-visible");
            observer.unobserve(entry.target);
          }
        });
      },
      {
        threshold: 0.1,
      }
    );

    document.querySelectorAll(".gallery-item").forEach((item) => {
      observer.observe(item);
    });

    return () => observer.disconnect();
  }, []);
  return (
    <>
      <Navbar1 />
      <Container fluid style={{ padding: "0px", position: "relative" }}>
        <video autoPlay muted loop playsInline controls={false} style={{ width: "100%" }} id="video">
          <source src={process.env.PUBLIC_URL + "/assets/fem-code.mp4"} type="video/mp4" />
          Il tuo browser non supporta il tag video.
        </video>
      </Container>

      {/* Sezione "cosa proponiamo"  */}
      <section
        id="about"
        className=" py-5"
        style={{
          backgroundColor: "#8B5BAF",
          color: "white",
          textAlign: "center",
          minHeight: "300px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Container>
          <Row className="justify-content-center">
            <Col md={10}>
              <h1
                className="about-title"
                style={{
                  fontFamily: "Poppins, sans-serif",
                  fontSize: "25px",
                  marginBottom: "20px",
                  color: "white",
                }}
              >
                Costruiamo il tuo <strong style={{ fontStyle: "italic", color: "#281E55" }}>successo</strong> Online
              </h1>

              <p
                className="about-text"
                style={{
                  fontSize: "18px",
                  lineHeight: "1.6",
                  maxWidth: "800px",
                  margin: "0 auto",
                }}
              >
                Il nostro obiettivo è aiutare le aziende e i professionisti a raggiungere il successo online attraverso
                la <strong>creazione di siti web personalizzati</strong>, la{" "}
                <strong>gestione delle pagine social</strong> e l'implementazione di strategie di{" "}
                <strong>ottimizzazione SEO</strong> su misura. Offriamo soluzioni digitali innovative che migliorano la{" "}
                <strong>visibilità online</strong> e generano risultati concreti.
                <br />
                <div style={{ textAlign: "center" }}>
                  <Link
                    to="/aboutus"
                    style={{
                      display: "inline-block",
                      padding: "10px 20px",
                      fontSize: "18px",
                      color: "white",
                      textDecoration: "none",
                      border: "2px solid white",
                      backgroundColor: "transparent",
                      transition: "background-color 0.3s ease, color 0.3s ease",
                      marginTop: "47px",
                      fontFamily: "Poppins, sans-serif",
                    }}
                    onMouseEnter={(e) => {
                      e.target.style.backgroundColor = "white";
                      e.target.style.color = "#333";
                    }}
                    onMouseLeave={(e) => {
                      e.target.style.backgroundColor = "transparent";
                      e.target.style.color = "white";
                    }}
                  >
                    Scopri come
                  </Link>
                </div>
              </p>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Sezione Gallery */}
      <section className="photo-gallery py-5">
        <Container className="mt-4 mb-4">
          <Row>
            {[
              { image: ImageGallery1, link: "https://www.artparis.it/" },
              { image: ImageGallery2, link: "https://komododecks.com/recordings/j5Nr8IWempNoMxXC2YQF" },
              { image: ImageGallery3, link: "https://komododecks.com/recordings/dGxqXM3P85Cq54KAdQ8S" },
              { image: ImageGallery4, link: "https://komododecks.com/recordings/31FF8VBcL4oQMRpSng1v" },
            ].map((item, index) => (
              <Col key={index} xs={12} sm={6} md={3} className="mb-4">
                <div className="gallery-item">
                  <img src={item.image} alt={`Gallery ${index + 1}`} className="gallery-image" />
                  <div className="gallery-overlay">
                    <Link
                      to={item.link}
                      style={{
                        fontFamily: "Poppins, sans-serif",
                        fontSize: "13px",
                        color: "white",
                        textDecoration: "none",
                      }}
                    >
                      Click Here
                    </Link>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
          {/* Bottone centrale "Progetti" */}
          <Row className="justify-content-center mt-4">
            <Col xs="auto">
              <Link to="/portfolio">
                <button
                  className="btn btn-primary"
                  style={{
                    fontFamily: "Poppins, sans-serif",
                    fontSize: "14px",
                    backgroundColor: "#c471ed",
                    border: "none",
                  }}
                >
                  I nostri lavori
                </button>
              </Link>
            </Col>
          </Row>
        </Container>
      </section>
      {/* servizi */}
      <Row className="justify-content-center">
        <Col md={10} className="text-center">
          <h1
            className="about-title"
            style={{
              fontFamily: "Poppins, sans-serif",
              fontSize: "18px",
              fontWeight: 400,
              letterSpacing: "2px",
              marginBottom: "20px",
              color: "#212225",
              padding: "20px",
              boxSizing: "border-box",
            }}
          >
            Servizi di Web Design e Digital Marketing per potenziare la tua presenza online.
          </h1>
        </Col>
      </Row>
      <section className="services-section py-5">
        <Container>
          <Row className="text-center">
            <Col md={4} className="mb-4">
              <div className="service-card">
                <img
                  src="https://images.pexels.com/photos/4348404/pexels-photo-4348404.jpeg?auto=compress&cs=tinysrgb&w=600"
                  alt="Creazione e Gestione Siti Web"
                  className="service-image img-fluid mb-3"
                />
                <h3
                  className="service-title"
                  style={{
                    fontFamily: "Poppins, sans-serif",
                    fontSize: "20px",
                    fontWeight: 400,
                    letterSpacing: "0px",
                    marginBottom: "20px",
                    color: "#dc64c5",
                  }}
                >
                  Creazione e Gestione Siti Web
                </h3>
                <p
                  style={{
                    fontFamily: "Poppins, sans-serif",
                    color: "#212225",
                    fontSize: "14px",
                  }}
                >
                  Costruiamo e gestiamo siti web professionali e ottimizzati per le performance.
                </p>
                <Link
                  to="/web-development"
                  style={{
                    backgroundColor: "transparent",
                    border: "1px solid black",
                    padding: "8px 16px",
                    color: "black",
                    borderRadius: "10px",
                    textDecoration: "none",
                    display: "inline-block",
                    fontSize: "14px",
                    fontWeight: "500",
                    letterSpacing: "1px",
                    transition: "background-color 0.3s ease, color 0.3s ease, transform 0.3s ease",
                    boxShadow: "none",
                  }}
                  onMouseOver={(e) => {
                    e.currentTarget.style.backgroundColor = "#282d31";
                    e.currentTarget.style.color = "white";
                    e.currentTarget.style.transform = "scale(1.05)";
                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.style.backgroundColor = "transparent";
                    e.currentTarget.style.color = "black";
                    e.currentTarget.style.transform = "scale(1)";
                  }}
                >
                  Scopri di più
                </Link>
              </div>
            </Col>

            <Col md={4} className="mb-4">
              <div className="service-card">
                <img
                  src="https://images.pexels.com/photos/218717/pexels-photo-218717.jpeg?auto=compress&cs=tinysrgb&w=600"
                  alt="Ottimizzazione SEO"
                  className="service-image img-fluid mb-3"
                />
                <h3
                  className="service-title"
                  style={{
                    fontFamily: "Poppins, sans-serif",
                    fontSize: "20px",
                    fontWeight: 400,
                    letterSpacing: "0px",
                    marginBottom: "20px",
                    color: "#dc64c5",
                  }}
                >
                  Ottimizzazione SEO
                </h3>
                <p
                  style={{
                    fontFamily: "Poppins, sans-serif",
                    color: "#212225",
                    fontSize: "14px",
                  }}
                >
                  Migliora la visibilità del tuo sito sui motori di ricerca con strategie SEO avanzate.
                </p>
                <Link
                  to="/seo"
                  style={{
                    backgroundColor: "transparent",
                    border: "1px solid black",
                    padding: "8px 16px",
                    color: "black",
                    borderRadius: "10px",
                    textDecoration: "none",
                    display: "inline-block",
                    fontSize: "14px",
                    fontWeight: "500",
                    letterSpacing: "1px",
                    transition: "background-color 0.3s ease, color 0.3s ease, transform 0.3s ease",
                    boxShadow: "none",
                  }}
                  onMouseOver={(e) => {
                    e.currentTarget.style.backgroundColor = "#282d31";
                    e.currentTarget.style.color = "white";
                    e.currentTarget.style.transform = "scale(1.05)";
                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.style.backgroundColor = "transparent";
                    e.currentTarget.style.color = "black";
                    e.currentTarget.style.transform = "scale(1)";
                  }}
                >
                  Scopri di più
                </Link>
              </div>
            </Col>

            <Col md={4} className="mb-4">
              <div className="service-card">
                <img
                  src="https://images.pexels.com/photos/1092671/pexels-photo-1092671.jpeg?auto=compress&cs=tinysrgb&w=600"
                  alt="Gestione Pagine Social"
                  className="service-image img-fluid mb-3"
                />
                <h3
                  className="service-title"
                  style={{
                    fontFamily: "Poppins, sans-serif",
                    fontSize: "20px",
                    fontWeight: 400,
                    letterSpacing: "0px",
                    marginBottom: "20px",
                    color: "#dc64c5",
                  }}
                >
                  Gestione Pagine Social
                </h3>
                <p
                  style={{
                    fontFamily: "Poppins, sans-serif",
                    color: "#212225",
                    fontSize: "14px",
                  }}
                >
                  Costruiamo la tua presenza online e coinvolgiamo il tuo pubblico attraverso strategie social efficaci.
                </p>
                <Link
                  to="/social"
                  style={{
                    backgroundColor: "transparent",
                    border: "1px solid black",
                    padding: "8px 16px",
                    color: "black",
                    borderRadius: "10px",
                    textDecoration: "none",
                    display: "inline-block",
                    fontSize: "14px",
                    fontWeight: "500",
                    letterSpacing: "1px",
                    transition: "background-color 0.3s ease, color 0.3s ease, transform 0.3s ease",
                    boxShadow: "none",
                  }}
                  onMouseOver={(e) => {
                    e.currentTarget.style.backgroundColor = "#282d31";
                    e.currentTarget.style.color = "white";
                    e.currentTarget.style.transform = "scale(1.05)";
                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.style.backgroundColor = "transparent";
                    e.currentTarget.style.color = "black";
                    e.currentTarget.style.transform = "scale(1)";
                  }}
                >
                  Scopri di più
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* social */}
      <section className="instagram-section" style={{ marginBottom: "5px" }}>
        <Container>
          <Row className="align-items-center">
            <Col xs={12} md={6} className="mb-4 mb-md-0">
              <div className="content">
                <h2>Unisciti a noi su Instagram!</h2>
                <p>Seguici per essere sempre aggiornato sulle ultime novità, anteprime e promozioni speciali.</p>
                <Link
                  to="https://www.instagram.com/femcode2024?igsh=NnA1eHp5MWtjeXU4"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="instagram-link"
                >
                  Seguici su Instagram
                </Link>
              </div>
            </Col>
            <Col xs={12} md={6}>
              <div className="image-container">
                <img src="/assets/instagram-foto.png" alt="Schermata Instagram" className="instagram-image img-fluid" />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Home;
