import React, { useState } from "react";
import "./GestioneSocial.css";
import Navbar1 from "./Navbar";
import heroVideo from "../assets/SocialMedia/hero.mp4";

const ServiceItem = ({ title, description, details }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="service-item">
      <h3>{title}</h3>
      <p>
        {description}
        <button className="toggle-button" onClick={toggleOpen}>
          {isOpen ? "-" : "+"}
        </button>
      </p>
      {isOpen && (
        <ul className="service-details">
          {details.map((detail, index) => (
            <li key={index}>{detail}</li>
          ))}
        </ul>
      )}
    </div>
  );
};

const GestioneSocial = () => {
  // Scrolla la pagina verso l'alto quando il componente viene montato
  useState(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
  }, []);

  return (
    <div className="gestione-social-container">
      <Navbar1 />
      <header className="hero-section">
        <video className="background-video" autoPlay loop muted playsInline controls={false}>
          <source src={heroVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        <div className="overlay"></div>
        <div className="hero-content">
          <h1>Servizi Professionali di Gestione Social Media</h1>
          <p>Ottimizza la tua presenza online con strategie di social media marketing su misura</p>
          <a href="#services" className="cta-button">
            Scopri di più
          </a>
        </div>
      </header>

      <section id="services" className="services-section">
        <h2 className="section-title">I Nostri Servizi di Gestione Social Media</h2>
        <ServiceItem
          title="Analisi del Target"
          description="Conduciamo un'analisi approfondita del target per capire gli interessi e i comportamenti del tuo pubblico di riferimento."
          details={[
            "Analisi dati demografici",
            "Studio delle preferenze di acquisto",
            "Monitoraggio comportamenti online",
          ]}
        />
        <ServiceItem
          title="Strategia Personalizzata di Social Media Marketing"
          description="Sviluppiamo una strategia personalizzata per migliorare la tua presenza sui principali social network."
          details={["Content marketing", "Campagne pubblicitarie su misura", "Gestione interazioni con i follower"]}
        />
        <ServiceItem
          title="Piano Editoriale per Social Media"
          description="Creiamo un piano editoriale per garantire che i tuoi contenuti siano pubblicati nei momenti giusti."
          details={["Calendario editoriale mensile", "Ottimizzazione dei post", "Analisi delle performance"]}
        />
        <ServiceItem
          title="Gestione Professionale delle Pagine Social"
          description="Offriamo un servizio completo di gestione delle pagine social, dalla pubblicazione alla gestione delle interazioni."
          details={["Gestione della community", "Monitoraggio delle prestazioni", "Report analitici dettagliati"]}
        />
      </section>

      <footer className="contact-section">
        <h2>Pronto a Far Crescere il Tuo Brand con i Social Media?</h2>
        <button className="cta-button" onClick={() => (window.location.href = "mailto:femcode2024@gmail.com")}>
          Contattaci Oggi
        </button>
      </footer>
    </div>
  );
};

export default GestioneSocial;
