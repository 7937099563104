import React, { useState, useEffect } from "react";
import { Container, Row, Col, Modal, Button } from "react-bootstrap";

import ImageGallery1 from "../assets/portfolio/art-paris.jpeg";
import ImageGallery2 from "../assets/portfolio/starblack.jpeg";
import ImageGallery3 from "../assets/portfolio/atena.jpeg";
import ImageGallery4 from "../assets/portfolio/beatrice.jpeg";
import ImageGallery5 from "../assets/portfolio/landing-page.jpeg";
import ImageGallery6 from "../assets/portfolio/sito-vetrina.jpeg";
import ImageGallery7 from "../assets/portfolio/catalogo.png";
import ImageGallery8 from "../assets/portfolio/sito-prenotazioni.png";

import Navbar1 from "./Navbar";

const Portfolio = () => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const handleItemClick = (item) => {
    setSelectedItem(item);
    setShowModal(true);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("is-visible");
            observer.unobserve(entry.target);
          }
        });
      },
      {
        threshold: 0.1,
      }
    );

    document.querySelectorAll(".gallery-item").forEach((item) => {
      observer.observe(item);
    });

    return () => observer.disconnect();
  }, []);

  return (
    <>
      <Navbar1 />

      <section className="photo-gallery py-5" style={{ marginTop: "30px" }}>
        <Container className="mt-4 mb-4">
          <Row>
            {[
              {
                image: ImageGallery1,
                description:
                  "Sito web per Parrucchiere ArtParis - Un design elegante e moderno per valorizzare i servizi di questo salone.",
                url: "https://www.artparis.it/",
              },
              {
                image: ImageGallery2,
                description:
                  "Un sito web e-commerce innovativo, progettato per il lancio e la vendita di gioielli di alta gamma. La piattaforma offre un'esperienza elegante e funzionalità integrate per l'acquisto di prodotti esclusivi, pensata per un pubblico sofisticato.",
                url: "https://komododecks.com/recordings/j5Nr8IWempNoMxXC2YQF",
              },
              {
                image: ImageGallery3,
                description:
                  "Sito web per Atena - Un layout semplice e pulito che evidenzia i servizi professionali offerti dall'azienda.",
                url: "https://komododecks.com/recordings/dGxqXM3P85Cq54KAdQ8S",
              },
              {
                image: ImageGallery4,
                description:
                  "Sito web per una makeup artist, con un design minimalista e gallery per mostrare i lavori fatti.",
                url: "https://komododecks.com/recordings/31FF8VBcL4oQMRpSng1v",
              },
              {
                image: ImageGallery5,
                description:
                  "Landing Page - Scopri la nuova collezione di abbigliamento, dove stile e qualità si fondono per creare capi moderni e sofisticati. Rinnova il tuo guardaroba con design unici e materiali pregiati, pensati per ogni occasione.",
                url: "https://komododecks.com/recordings/aQEwEQBdo3aruXqfU0zG",
              },
              {
                image: ImageGallery6,
                description:
                  "Sito Web Ristorante - Una piattaforma con design intuitivo, che permette di esplorare il menù, scoprire la storia del locale. Navigazione semplice e informazioni essenziali a portata di mano.",
                url: "https://komododecks.com/recordings/9NJP5SzSYkcBeHp1rDrC",
              },
              {
                image: ImageGallery7,
                description:
                  "Un sito web catalogo offre una panoramica completa dei tuoi prodotti o servizi, permettendo ai visitatori di esplorare le offerte senza necessità di acquistare online. È ideale per mostrare dettagli e caratteristiche in modo ordinato e intuitivo",
                url: "https://komododecks.com/recordings/gQXzwfmZjMPZnQirGudF",
              },
              {
                image: ImageGallery8,
                description:
                  "Un sito web di prenotazione per una palestra consente agli utenti di visualizzare i corsi disponibili e prenotare lezioni con facilità. Grazie a un'interfaccia intuitiva, la gestione degli orari e delle iscrizioni diventa semplice e immediata",
                url: "https://komododecks.com/recordings/c5mla7CBR4xkPQ7lhcC5",
              },
            ].map((item, index) => (
              <Col key={index} xs={12} sm={6} md={4} className="mb-4">
                <div
                  className="gallery-item"
                  onClick={() => handleItemClick(item)}
                  style={{ height: "200px", overflow: "hidden", borderRadius: "10px" }}
                >
                  <img
                    src={item.image}
                    alt={`Gallery ${index + 1}`}
                    className="gallery-image"
                    style={{ objectFit: "cover", width: "100%", height: "100%" }}
                  />
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </section>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Descrizione del Sito Web</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedItem && (
            <>
              <img src={selectedItem.image} alt="Selected" className="img-fluid mb-3" />
              <p>{selectedItem.description}</p>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          {selectedItem && (
            <Button
              variant="primary"
              onClick={() => window.open(selectedItem.url, "_blank")}
              style={{ backgroundColor: "black", borderColor: "black" }}
            >
              Scopri di più
            </Button>
          )}
        </Modal.Footer>
      </Modal>

      {/* Sezione Contattami */}
      <section className="contact-me-section py-5 text-center" style={{ backgroundColor: "#eacdf1" }}>
        <Container>
          <h2 className="mb-4" style={{ color: "#4b3f54" }}>
            Richiedi un preventivo gratuito
          </h2>
          <p className="mb-4" style={{ color: "#4b3f54" }}>
            Hai un progetto in mente? Parlaci del tuo progetto o scrivici una email.
          </p>
          <Button
            variant="primary"
            className="m-2"
            onClick={() =>
              window.open(
                "https://docs.google.com/forms/d/e/1FAIpQLScSMUGwiru3gHRyslSzKSibylGvE9xTVUZOm1GGLiSY6IVCcw/viewform?usp=sf_link",
                "_blank"
              )
            }
            style={{ backgroundColor: "#d090d8", borderColor: "#d090d8" }}
          >
            Parlaci del tuo progetto
          </Button>
          <Button
            variant="outline-light"
            className="m-2"
            onClick={() => (window.location.href = "mailto:femcode2024@gmail.com")}
            style={{ color: "#4b3f54", borderColor: "#4b3f54" }}
          >
            Scrivi una email
          </Button>
        </Container>
      </section>
    </>
  );
};

export default Portfolio;
