import { Helmet } from "react-helmet";
import Slider from "react-slick";
import "./WebDevelopment.css";
import techImage from "../assets/WebDevelopment/4.jpg";
import techImage1 from "../assets/WebDevelopment/e-commerce.jpg";
import techImage2 from "../assets/WebDevelopment/8.jpg";
import techImage3 from "../assets/WebDevelopment/sito-web-vetrina.jpg";
import techImage4 from "../assets/WebDevelopment/6.jpg";
import techImage5 from "../assets/WebDevelopment/1.jpg";
import techImage6 from "../assets/WebDevelopment/5.jpg";
import Navbar1 from "./Navbar";
import React, { useState, useEffect } from "react";
import { Container, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const images = [
  {
    src: techImage3,
    alt: "Sito Web Vetrina",
    text: "Un sito vetrina è il biglietto da visita digitale ideale per le aziende che desiderano una presenza online efficace.",
  },
  {
    src: techImage2,
    alt: "Landing Page",
    text: "Scopri la nostra soluzione di landing page: un sito web elegante e funzionale, composto da una singola pagina.",
  },
  {
    src: techImage1,
    alt: "E-commerce",
    text: "Un negozio online è sempre aperto, consentendo ai clienti di acquistare 24 ore su 24, 7 giorni su 7.",
  },
  {
    src: techImage4,
    alt: "Sito Web Catalogo",
    text: "Il sito web catalogo è la soluzione ideale per promuovere i tuoi prodotti e servizi senza vendere direttamente online.",
  },
  {
    src: techImage6,
    alt: "Piattaforma gestione prenotazioni",
    text: "Scopri il nostro sistema di prenotazione online, progettato per semplificare la pianificazione di appuntamenti.",
  },
  {
    src: techImage5,
    alt: "Pacchetti personalizzati",
    text: "Progettiamo pacchetti su misura per soddisfare le tue esigenze specifiche.",
  },
  {
    src: techImage,
    alt: "Portfolio",
    text: "Metti in mostra il tuo talento con un portfolio professionale e personalizzato!",
  },
];

const SampleNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        color: "white",
        width: "40px",
        height: "40px",
        textAlign: "center",
        lineHeight: "40px",
        marginRight: "25px",
      }}
      onClick={onClick}
    >
      &gt;
    </div>
  );
};

const SamplePrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        color: "white",
        width: "40px",
        height: "40px",
        textAlign: "center",
        lineHeight: "40px",
        marginLeft: "25px",
      }}
      onClick={onClick}
    >
      &lt;
    </div>
  );
};

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 6,
  slidesToScroll: 1,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
  ],
};

const WebDevelopment = () => {
  const navigate = useNavigate();
  const [selectedImage, setSelectedImage] = useState(images[0]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
  }, []);

  const handleImageHover = (image) => {
    setSelectedImage(image);
  };

  const handleImageClick = (image) => {
    const routes = {
      "Sito Web Vetrina": "/sito-vetrina",
      "Landing Page": "/landing-page",
      "E-commerce": "/e-commerce",
      "Sito Web Catalogo": "/catalogo",
      "Piattaforma gestione prenotazioni": "/prenotazioni",
      "Pacchetti personalizzati": "",
      Portfolio: "/portfolio-service",
    };

    const route = routes[image.alt];
    if (route) navigate(route);
  };

  return (
    <>
      <Navbar1 />

      <Helmet>
        <title>Creazione e Gestione Siti Web Professionali</title>
        <meta
          name="description"
          content="Scopri i nostri servizi di creazione e gestione siti web professionali. Ottimizzati per SEO, responsive e perfetti per la tua strategia digitale."
        />
        <meta name="keywords" content="creazione siti web, gestione siti web, SEO, sviluppo web, siti responsive" />
        <link rel="canonical" href="https://www.tuosito.com/creazione-gestione-siti-web" />
      </Helmet>

      <main>
        <div className="title">
          <h2>Creazione e Gestione Siti Web</h2>
          <p style={{ padding: "20px", boxSizing: "border-box" }}>
            Avere un <strong>sito web</strong> di alta qualità è fondamentale per comunicare con i{" "}
            <strong>clienti</strong>. In <strong>FemCode</strong>, realizziamo <strong>siti web</strong> esteticamente
            curati per presentare <strong>prodotti</strong> e <strong>servizi</strong>, aiutandoti a raggiungere i tuoi{" "}
            <strong>obiettivi aziendali</strong>.
          </p>
        </div>

        <div className="carousel-container">
          <Slider {...settings}>
            {images.map((image, index) => (
              <div
                key={index}
                className="carousel-slide"
                onMouseEnter={() => handleImageHover(image)}
                onClick={() => handleImageClick(image)}
                style={{ cursor: "pointer" }}
              >
                <img src={image.src} alt={image.alt} />
                <div className="image-caption">{image.alt}</div>
              </div>
            ))}
          </Slider>
        </div>

        <section className="additional-content">
          <h3>{selectedImage?.alt || "Seleziona un'immagine"}</h3>
          <p>{selectedImage?.text || "Passa il mouse su un'immagine per visualizzare i dettagli."}</p>
        </section>

        <section className="contact-me-section py-5 text-center">
          <Container>
            <h2 className="mb-4" style={{ color: "#4b3f54" }}>
              Richiedi un preventivo gratuito
            </h2>
            <p className="mb-4" style={{ color: "#4b3f54" }}>
              Hai un progetto in mente? Parlaci del tuo progetto o scrivici una email.
            </p>
            <Button
              variant="primary"
              className="m-2"
              onClick={() =>
                window.open(
                  "https://docs.google.com/forms/d/e/1FAIpQLScSMUGwiru3gHRyslSzKSibylGvE9xTVUZOm1GGLiSY6IVCcw/viewform?usp=sf_link",
                  "_blank"
                )
              }
              style={{
                backgroundColor: "#d090d8",
                borderColor: "#d090d8",
              }}
            >
              Parlaci del tuo progetto
            </Button>
            <Button
              variant="outline-light"
              className="m-2"
              onClick={() => (window.location.href = "mailto:femcode2024@gmail.com")}
              style={{ color: "#4b3f54", borderColor: "#4b3f54" }}
            >
              Scrivi una email
            </Button>
          </Container>
        </section>
      </main>
    </>
  );
};

export default WebDevelopment;
