import React, { useEffect } from "react";
import { Container, Button } from "react-bootstrap";
import Navbar1 from "./Navbar";
import { Helmet } from "react-helmet";
import "./WebDevelopment.css";

const Ecommerce = () => {
  // Scrolla la pagina verso l'alto quando il componente viene montato
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
  }, []);

  return (
    <>
      <Navbar1 />

      <Helmet>
        <title>Sito E-commerce</title>
        <meta
          name="description"
          content="Scopri come realizziamo siti e-commerce funzionali e performanti per vendere online in modo efficace."
        />
        <meta name="keywords" content="e-commerce, sito web, negozio online, SEO, sviluppo web, design web" />
        <link rel="canonical" href="https://www.tuosito.com/ecommerce" />
      </Helmet>

      <div className="title">
        <h2>Sito E-commerce</h2>
        <p style={{ padding: "20px", boxSizing: "border-box" }}>
          Un sito e-commerce è cruciale per vendere online. Offriamo design personalizzato, gestione dei carrelli,
          navigazione fluida, pagamenti sicuri e ottimizzazione SEO per migliorare la visibilità.
        </p>
      </div>

      <Container
        fluid
        style={{
          padding: "0px",
          position: "relative",
          marginTop: "90px",
          marginBottom: "90px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ position: "relative", width: "80%", maxWidth: "900px" }}>
          <video
            autoPlay
            controls
            muted
            loop
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
            }}
          >
            <source src={process.env.PUBLIC_URL + "/assets/e-commerce.mp4"} type="video/mp4" />
            Il tuo browser non supporta il tag video.
          </video>
        </div>
      </Container>

      <main>
        <section className="additional-content">
          <h3>Dettagli essenziali</h3>

          <p style={{ padding: "20px", boxSizing: "border-box" }}>
            Ci focalizziamo sulla sicurezza delle transazioni, gestione del magazzino e velocità di caricamento. Ogni
            e-commerce è responsivo e ottimizzato per i motori di ricerca, garantendo un'esperienza utente ottimale su
            tutti i dispositivi.
          </p>
        </section>

        <section className="contact-me-section py-5 text-center">
          <Container>
            <h2 className="mb-4" style={{ color: "#4b3f54" }}>
              Richiedi un preventivo gratuito
            </h2>
            <p className="mb-4" style={{ color: "#4b3f54" }}>
              Hai un progetto in mente? Parlaci del tuo e-commerce o scrivici una email.
            </p>
            <Button
              variant="primary"
              className="m-2"
              onClick={() =>
                window.open(
                  "https://docs.google.com/forms/d/e/1FAIpQLScSMUGwiru3gHRyslSzKSibylGvE9xTVUZOm1GGLiSY6IVCcw/viewform?usp=sf_link",
                  "_blank"
                )
              }
              style={{ backgroundColor: "#d090d8", borderColor: "#d090d8" }}
            >
              Parlaci del tuo progetto
            </Button>
            <Button
              variant="outline-light"
              className="m-2"
              onClick={() => (window.location.href = "mailto:femcode2024@gmail.com")}
              style={{ color: "#4b3f54", borderColor: "#4b3f54" }}
            >
              Scrivi una email
            </Button>
          </Container>
        </section>
      </main>
    </>
  );
};

export default Ecommerce;
