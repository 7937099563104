import React from "react";
import Navbar1 from "./Navbar";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import techImage1 from "../assets/about-us/foto1.jpg";
import techImage2 from "../assets/about-us/foto2.jpg";
import techImage3 from "../assets/about-us/foto3.jpg";
import techImage4 from "../assets/about-us/foto5.jpg";

const AboutUs = () => {
  return (
    <>
      <Navbar1 />
      <Container fluid style={{ padding: "0px", position: "relative" }}>
        <video autoPlay muted loop playsInline controls={false} style={{ width: "100%" }} id="video">
          <source src={process.env.PUBLIC_URL + "/assets/chisiamo.mp4"} type="video/mp4" />
          Il tuo browser non supporta il tag video.
        </video>
      </Container>

      {/* su di noi */}
      <div
        className="extra-section"
        style={{ marginTop: "50px", padding: "40px 20px", backgroundColor: "white", marginBottom: "40px" }}
      >
        <div className="extra-section-content" style={{ textAlign: "center" }}>
          <h2
            style={{
              fontFamily: "Poppins, sans-serif",
              color: "black",
              padding: "1px 5px",
              backgroundColor: "#eacdf1",
              display: "inline",
              fontSize: "25px",
            }}
          >
            Su di noi
          </h2>

          <p
            style={{
              fontFamily: "Poppins, sans-serif",
              color: "#555",
              maxWidth: "800px",
              margin: "0 auto",
              marginTop: "20px",
              fontSize: "18px",
              padding: "20px",
              boxSizing: "border-box",
            }}
          >
            Siamo <strong>FemCode</strong>, un team di sviluppatrici appassionate e creative, dedicate a fornire
            soluzioni digitali su misura. Uniamo competenze tecniche avanzate e visione innovativa per trasformare le
            vostre idee in esperienze digitali straordinarie.
          </p>
        </div>
      </div>

      {/* Cosa facciamo */}
      <div className="noi-section" style={{ padding: "40px 20px", backgroundColor: "#f9f9f9", textAlign: "center" }}>
        <Row className="services">
          {/* Siti Web Personalizzati */}
          <Col xs={12} md={6} className="mb-4">
            <Link to="/web-development" className="no-underline">
              <h3 style={{ fontFamily: "Poppins, sans-serif", color: "#333" }}>Siti Web Personalizzati</h3>
              <p style={{ fontFamily: "Poppins, sans-serif", color: "#555" }}>
                Da semplici siti per la tua attività a complessi e-commerce. Soluzioni scalabili per aziende di tutte le
                dimensioni.
              </p>
              <img src={techImage1} alt="Custom Websites" style={{ width: "100%", borderRadius: "10px" }} />
            </Link>
          </Col>

          {/* Ottimizzazione SEO */}
          <Col xs={12} md={6} className="mb-4">
            <Link to="/seo" className="no-underline">
              <h3 style={{ fontFamily: "Poppins, sans-serif", color: "#333" }}>Ottimizzazione SEO</h3>
              <p style={{ fontFamily: "Poppins, sans-serif", color: "#555" }}>
                Migliora la visibilità del tuo sito sui motori di ricerca con strategie SEO avanzate. Ottimizza i tuoi
                contenuti e le prestazioni del sito.
              </p>

              <img src={techImage2} alt="SEO Optimization" style={{ width: "100%", borderRadius: "10px" }} />
            </Link>
          </Col>

          {/* Consulenza Tecnologica */}
          <Col xs={12} md={6} className="mb-4">
            <h3 style={{ fontFamily: "Poppins, sans-serif", color: "#333" }}>Consulenza Tecnologica</h3>
            <p style={{ fontFamily: "Poppins, sans-serif", color: "#555" }}>
              Offriamo consulenza mirata per identificare le opportunità tecnologiche e ottimizzare le vostre strategie
              digitali.
            </p>
            <img src={techImage3} alt="Tech Consulting" style={{ width: "100%", borderRadius: "10px" }} />
          </Col>

          {/* Gestione Pagine Social */}
          <Col xs={12} md={6} className="mb-4">
            <Link to="/social" className="no-underline">
              <h3 style={{ fontFamily: "Poppins, sans-serif", color: "#333" }}>Gestione Pagine Social</h3>
              <p style={{ fontFamily: "Poppins, sans-serif", color: "#555" }}>
                Costruiamo la tua presenza online e coinvolgiamo il tuo pubblico attraverso strategie social efficaci.
              </p>
              <img src={techImage4} alt="Social Media Management" style={{ width: "100%", borderRadius: "10px" }} />
            </Link>
          </Col>
        </Row>
      </div>

      {/* Mission */}
      <div
        className="extra-section"
        style={{ marginTop: "20px", padding: "40px 20px", backgroundColor: "white", marginBottom: "40px" }}
      >
        <div className="extra-section-content" style={{ textAlign: "center" }}>
          <h2
            style={{
              fontFamily: "Poppins, sans-serif",
              color: "black",
              padding: "1px 0", // effetto evidenziatore
              backgroundColor: "#eacdf1",
              display: "inline",
            }}
          >
            La Nostra Missione
          </h2>

          <p
            style={{
              fontFamily: "Poppins, sans-serif",
              color: "#555",
              maxWidth: "800px",
              margin: "0 auto",
              marginTop: "20px",
            }}
          >
            Aiutare le imprese e gli individui a crescere e innovare nel mondo digitale con soluzioni tecnologiche
            all'avanguardia e strategie di marketing efficaci.
          </p>
        </div>
      </div>

      {/* LinkedIn */}
      <section className="linkedin-section py-5 text-center" style={{ backgroundColor: "#eacdf1" }}>
        <Container>
          <h2 className="mb-4" style={{ color: "#4b3f54" }}>
            Connettiti con noi su LinkedIn
          </h2>
          <p className="mb-4" style={{ color: "#4b3f54" }}>
            Scopri di più su di noi, il nostro team e i nostri progetti seguendoci su LinkedIn.
          </p>
          <Button
            variant="primary"
            className="m-2"
            onClick={() =>
              window.open("https://www.linkedin.com/company/femcode2024/about/?viewAsMember=true", "_blank")
            }
            style={{ backgroundColor: "#d090d8", borderColor: "#d090d8" }}
          >
            Colleghiamoci su LinkedIn
          </Button>
        </Container>
      </section>
    </>
  );
};

export default AboutUs;
