import React, { useState, useEffect } from "react";
import { Navbar as BootstrapNavbar, Nav, Container, Modal, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import Logo from "../assets/navbar/logo.png";

const Navbar = () => {
  const [showModal, setShowModal] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [activeLink, setActiveLink] = useState(window.location.pathname);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 992);

  const handleToggle = () => {
    setShowModal(!showModal);
  };

  const handleScroll = () => {
    const scrolled = window.scrollY > 100;
    setIsScrolled(scrolled);
  };

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 992);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <BootstrapNavbar
        expand="lg"
        className={`custom-navbar  ${isScrolled ? "navbar-transparent" : ""}`}
        style={{ paddingBottom: 0, backgroundColor: "white" }}

        // className={`custom-navbar navbar-purple fixed-top ${isScrolled ? "navbar-transparent" : ""}`}
        // style={{ paddingBottom: 0, backgroundColor: "white" }}
      >
        <Container fluid>
          {/* Logo a sinistra */}
          <BootstrapNavbar.Brand as={Link} to="/" className="d-flex align-items-center">
            <Image src={Logo} alt="Logo" height="29" style={{ marginLeft: "10px" }} />
          </BootstrapNavbar.Brand>

          {/* Menu a destra */}
          {!isMobile && (
            <Nav id="menu_items" className="ml-auto d-flex align-items-center">
              <Nav.Link
                as={Link}
                to="/"
                className={`nav-link nav-item ${activeLink === "/" ? "active" : ""}`}
                onClick={() => setActiveLink("/")}
                style={{ color: "black", fontSize: "14px", marginRight: "20px" }}
              >
                <span>Home</span>
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/aboutus"
                className={`nav-link nav-item ${activeLink === "/chi-siamo" ? "active" : ""}`}
                onClick={() => setActiveLink("/chi-siamo")}
                style={{ color: "black", fontSize: "14px", marginRight: "20px" }}
              >
                <span>Chi siamo</span>
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/portfolio"
                className={`nav-link nav-item ${activeLink === "/collezione" ? "active" : ""}`}
                onClick={() => setActiveLink("/collezione")}
                style={{ color: "black", fontSize: "14px", marginRight: "20px" }}
              >
                <span>Portfolio</span>
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/contact"
                className={`nav-link nav-item ${activeLink === "/contact" ? "active" : ""}`}
                onClick={() => setActiveLink("/contact")}
                style={{ color: "black", fontSize: "14px" }}
              >
                <span style={{ marginRight: "10px" }}>Contatti</span>
              </Nav.Link>
            </Nav>
          )}

          {isMobile && (
            <button
              id="menu_button"
              className={`custom-toggler ${showModal ? "active" : ""}`}
              aria-controls="basic-navbar-nav"
              aria-expanded={showModal}
              aria-label="Toggle navigation"
              onClick={handleToggle}
            >
              <span className="bar bar-top"></span>
              <span className="bar bar-middle"></span>
              <span className="bar bar-bottom"></span>
            </button>
          )}
        </Container>
      </BootstrapNavbar>

      {/* Modale */}
      <Modal show={showModal} onHide={handleToggle} backdrop={false} dialogClassName="custom-modal">
        <Modal.Header closeButton>
          <Modal.Title>Menu</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Nav className="flex-column">
            <Nav.Link
              as={Link}
              to="/"
              className={`nav-link nav-item ${activeLink === "/service" ? "active" : ""}`}
              onClick={() => {
                setActiveLink("/service");
                handleToggle();
              }}
            >
              <span style={{ color: "black" }}>Home</span>
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/aboutus"
              className={`nav-link nav-item ${activeLink === "/hours" ? "active" : ""}`}
              onClick={() => {
                setActiveLink("/aboutus");
                handleToggle();
              }}
            >
              <span style={{ color: "black" }}>Chi siamo</span>
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/portfolio"
              className={`nav-link nav-item ${activeLink === "/" ? "active" : ""}`}
              onClick={() => {
                setActiveLink("/portfolio");
                handleToggle();
              }}
            >
              <span style={{ color: "black" }}>Portfolio</span>
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/contact"
              className={`nav-link nav-item ${activeLink === "/contact" ? "active" : ""}`}
              onClick={() => {
                setActiveLink("/contact");
                handleToggle();
              }}
            >
              <span style={{ color: "black" }}>Contatti</span>
            </Nav.Link>
          </Nav>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Navbar;
