import React, { useEffect } from "react";
import { Container, Button } from "react-bootstrap";
import Navbar1 from "./Navbar";
import { Helmet } from "react-helmet";
import "./WebDevelopment.css";

const GestionePrenotazioni = () => {
  // Scrolla la pagina verso l'alto quando il componente viene montato
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
  }, []);

  return (
    <>
      <Navbar1 />

      <Helmet>
        <title>Piattaforma Gestione Prenotazioni</title>
        <meta
          name="description"
          content="Scopri come creiamo piattaforme di gestione prenotazioni efficienti per aziende che necessitano di organizzare appuntamenti e servizi in modo semplice e intuitivo."
        />
        <meta
          name="keywords"
          content="gestione prenotazioni, piattaforma prenotazioni, appuntamenti online, servizi, sviluppo web"
        />
        <link rel="canonical" href="https://www.tuosito.com/gestione-prenotazioni" />
      </Helmet>

      <div className="title">
        <h2>Piattaforma Gestione Prenotazioni</h2>
        <p style={{ padding: "20px", boxSizing: "border-box" }}>
          Una piattaforma di gestione delle prenotazioni automatizza la gestione di appuntamenti e servizi, ideale per
          attività come studi, ristoranti e centri estetici. Intuitiva e ottimizzata, consente ai clienti di prenotare
          online facilmente.
        </p>
      </div>

      <Container
        fluid
        style={{
          padding: "0px",
          position: "relative",
          marginTop: "90px",
          marginBottom: "90px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ position: "relative", width: "80%", maxWidth: "900px" }}>
          <video
            autoPlay
            controls
            muted
            loop
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
            }}
          >
            <source src={process.env.PUBLIC_URL + "/assets/prenotazioni.mp4"} type="video/mp4" />
            Il tuo browser non supporta il tag video.
          </video>
        </div>
      </Container>

      <main>
        <section className="additional-content">
          <h3>Punti chiave</h3>
          <p style={{ padding: "20px", boxSizing: "border-box" }}>
            Progettiamo piattaforme di prenotazione intuitive, con gestione autonoma del calendario, conferme
            automatiche, integrazione con pagamenti, notifiche e ottimizzazione mobile, per una gestione clienti più
            efficiente.
          </p>
        </section>

        <section className="contact-me-section py-5 text-center">
          <Container>
            <h2 className="mb-4" style={{ color: "#4b3f54" }}>
              Richiedi un preventivo gratuito
            </h2>
            <p className="mb-4" style={{ color: "#4b3f54" }}>
              Hai un progetto in mente? Parlaci della tua piattaforma di gestione prenotazioni o scrivici una email.
            </p>
            <Button
              variant="primary"
              className="m-2"
              onClick={() =>
                window.open(
                  "https://docs.google.com/forms/d/e/1FAIpQLScSMUGwiru3gHRyslSzKSibylGvE9xTVUZOm1GGLiSY6IVCcw/viewform?usp=sf_link",
                  "_blank"
                )
              }
              style={{ backgroundColor: "#d090d8", borderColor: "#d090d8" }}
            >
              Parlaci del tuo progetto
            </Button>
            <Button
              variant="outline-light"
              className="m-2"
              onClick={() => (window.location.href = "mailto:femcode2024@gmail.com")}
              style={{ color: "#4b3f54", borderColor: "#4b3f54" }}
            >
              Scrivi una email
            </Button>
          </Container>
        </section>
      </main>
    </>
  );
};

export default GestionePrenotazioni;
