import React from "react";
import facebookIcon from "../assets/social-icon/icons8-facebook-48.png";
import instagramIcon from "../assets/social-icon/icons8-instagram-48 (1).png";
import linkedinIcon from "../assets/social-icon/icons8-linkedin-48.png";
import footerImage from "../assets/footer/logo.png";
import emailIcon from "../assets/social-icon/icons8-posta-elettronica-48.png";

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <footer className="footer">
      <div className="footer-content">
        <p style={{ fontFamily: "Montserrat, sans-serif" }}>Copyright © 2024 FemCode</p>
        <div className="social-icons">
          <a
            href="https://www.instagram.com/femcode2024?igsh=NnA1eHp5MWtjeXU4"
            target="_blank"
            rel="noopener noreferrer"
            className="nav-link"
          >
            <img src={instagramIcon} alt="Instagram" className="footer-icon" />
          </a>

          <a
            href="https://www.facebook.com/profile.php?id=61564797302560"
            target="_blank"
            rel="noopener noreferrer"
            className="nav-link"
          >
            <img src={facebookIcon} alt="Facebook" className="footer-icon" />
          </a>
          <a href="mailto:femcode2024@gmail.com" className="nav-link">
            <img src={emailIcon} alt="Email" className="footer-icon" />
          </a>
          <a
            href="https://www.linkedin.com/company/femcode2024/about/?viewAsMember=true"
            target="_blank"
            rel="noopener noreferrer"
            className="nav-link"
          >
            <img src={linkedinIcon} alt="linkedin" className="footer-icon" />
          </a>
        </div>
        <div className="footer-image">
          <img
            src={footerImage}
            alt="Footer Decorative"
            style={{ width: "100px", height: "auto", marginTop: "13px" }}
          />
        </div>
      </div>
      {/* <div className="scroll-to-top" onClick={scrollToTop}>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12 4L4 12H8V20H16V12H20L12 4Z" fill="white" />
        </svg>
      </div> */}
    </footer>
  );
};

export default Footer;
