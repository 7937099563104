import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";

//css

import "./components/portfolio.css";
import "./components/navbar.css";
import "./components/footer.css";
import "./components/home.css";
import "./components/aboutus.css";
import "./components/contact.css";

import Home from "./components/Home.jsx";
import Footer from "../src/components/Footer.jsx";
import Contact from "./components/Contact.jsx";
import AboutUs from "./components/AboutUs.jsx";
import Portfolio from "./components/Portfolio.jsx";
import WebDevelopment from "./components/WebDevelopment.jsx";
import GestioneSocial from "./components/GestioneSocial.jsx";
import Seo from "./components/Seo.jsx";
import SitoVetrina from "./components/SitoVetrina.jsx";
import Ecommerce from "./components/Ecommerce.jsx";
import LandingPage from "./components/LandingPage.jsx";
import SitoCatalogo from "./components/SitoCatalogo.jsx";
import GestionePrenotazioni from "./components/GestionePrenotazioni.jsx";
import PortfolioService from "./components/PortfolioService.jsx";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/web-development" element={<WebDevelopment />} />
        <Route path="/seo" element={<Seo />} />
        <Route path="/social" element={<GestioneSocial />} />
        <Route path="/sito-vetrina" element={<SitoVetrina />} />
        <Route path="/e-commerce" element={<Ecommerce />} />
        <Route path="/landing-page" element={<LandingPage />} />
        <Route path="/catalogo" element={<SitoCatalogo />} />
        <Route path="/prenotazioni" element={<GestionePrenotazioni />} />
        <Route path="/portfolio-service" element={<PortfolioService />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
