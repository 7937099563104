import { Helmet } from "react-helmet";
import "./Seo.css";
import seoImage1 from "../assets/SEO/seo1.jpg";
import seoImage2 from "../assets/SEO/seo2.jpg";
import seoImage3 from "../assets/SEO/seo3.jpg";
import seoImage4 from "../assets/SEO/seo10.jpg";
import Navbar1 from "./Navbar";
import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";

// Define SEO services data
const seoServices = [
  {
    src: seoImage1,
    alt: "Ottimizzazione del tuo sito",
    text: "L'ottimizzazione del sito migliora la visibilità su Google, rendendolo più attraente. Ottimizziamo contenuti, parole chiave e struttura per posizionarti tra i primi risultati nelle ricerche dei tuoi prodotti o servizi.",
  },
  {
    src: seoImage2,
    alt: "Promozione Online",
    text: "La promozione online amplia la tua presenza su siti e social media. Creiamo contenuti e facciamo link building per aumentare il traffico al sito e migliorare la tua reputazione e autorità online.",
  },
  {
    src: seoImage3,
    alt: "Ottimizzazione Tecnica",
    text: "L'ottimizzazione tecnica migliora la velocità, corregge errori e ottimizza la struttura del sito per facilitare l'indicizzazione, garantendo una migliore esperienza utente.",
  },
  {
    src: seoImage4,
    alt: "Report e monitoraggio",
    text: "Monitoriamo le prestazioni del sito con report chiari e metriche chiave per valutare l'efficacia SEO. Forniamo analisi dettagliate e suggerimenti basati sui dati per ottimizzare la visibilità online.",
  },
];

const Seo = () => {
  // Scroll to the top of the page when the component is mounted
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
  }, []);

  return (
    <>
      <Navbar1 />

      <Helmet>
        <title>Servizi SEO Professionali</title>
        <meta
          name="description"
          content="Offriamo servizi SEO professionali per migliorare la visibilità del tuo sito web sui motori di ricerca. Ottimizzazione On-Page, Off-Page, tecnico e analisi avanzata."
        />
        <meta name="keywords" content="SEO, ottimizzazione SEO, SEO On-Page, SEO Off-Page, SEO tecnico, analisi SEO" />
        <link rel="canonical" href="https://www.tuosito.com/servizi-seo" />
      </Helmet>

      <main>
        <div className="seo-title-section">
          <h2
            style={{
              fontFamily: "Poppins, sans-serif",
              color: "black",
              padding: "1px 5px",
              backgroundColor: "#eacdf1",
              display: "inline",
              fontSize: "25px",
            }}
          >
            Servizi SEO professionali
          </h2>
          <p className="seo-description">
            Per emergere online, una strategia SEO efficace è essenziale. Offriamo servizi SEO per migliorare la tua
            visibilità su Google, assicurando che il tuo sito sia ben posizionato e raggiunga più persone.
          </p>
        </div>

        {/* SEO services section */}
        <section className="seo-services-list">
          <Container>
            <Row>
              {seoServices.map((service, index) => (
                <Col xs={12} md={6} className="mb-4" key={index}>
                  <div className="seo-service-item">
                    <img src={service.src} alt={service.alt} className="seo-service-image" />
                    <div className="seo-service-content">
                      <h3>{service.alt}</h3>
                      <p>{service.text}</p>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </Container>
        </section>

        {/* <!-- LinkedIn Section --> */}
        <section class="linkedin-section">
          <h2>Connettiti con noi su LinkedIn</h2>
          <p>Scopri di più su di noi, il nostro team e i nostri progetti seguendoci su LinkedIn.</p>
          <a
            href="https://www.linkedin.com/company/femcode2024/"
            class="linkedin-button"
            style={{ textDecoration: "none" }}
          >
            Colleghiamoci su LinkedIn
          </a>
        </section>
      </main>
    </>
  );
};

export default Seo;
