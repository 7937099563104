import React, { useState } from "react";
import Navbar1 from "./Navbar";
import whatsappIcon from "../assets/social-icon/whatsapp.png";
import { Button } from "react-bootstrap";
import imageSrc from "../assets/contact/contatti2.jpg";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const [formStatus, setFormStatus] = useState({ submitted: false, message: "" });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Invia i dati a Formspree
    fetch("https://formspree.io/f/manwvyzl", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (response.ok) {
          setFormStatus({
            submitted: true,
            message: "Grazie per aver inviato il modulo! Verrai contattato al più presto.",
          });
          setFormData({
            name: "",
            email: "",
            phone: "",
            message: "",
          }); // Resetta il form dopo l'invio
        } else {
          setFormStatus({ submitted: true, message: "Errore nell'invio del modulo. Riprova." });
        }
      })
      .catch((error) => {
        console.error("Errore:", error);
        setFormStatus({ submitted: true, message: "Errore nell'invio del modulo. Riprova." });
      });
  };

  return (
    <>
      <Navbar1 />
      <div className="contact-container">
        {/* Sezione immagine */}
        <div className="image-section" style={{ backgroundImage: `url(${imageSrc})` }} />

        {/* Sezione form */}
        <div className="form-section">
          <h2 style={{ marginBottom: "20px" }}>Contattaci</h2>
          <p>Compila il modulo sottostante per inviarci un messaggio. Ti risponderemo al più presto.</p>
          {formStatus.submitted && (
            <div
              className="form-message"
              style={{
                marginBottom: "20px",
                color: formStatus.message.includes("Errore") ? "red" : "#4A2C77",
              }}
            >
              {formStatus.message}
            </div>
          )}
          <form onSubmit={handleSubmit} className="custom-form">
            <div style={{ marginBottom: "15px" }}>
              <label htmlFor="name">Nome *</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
                style={{
                  width: "100%",
                  padding: "10px",
                  boxSizing: "border-box",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                }}
              />
            </div>
            <div style={{ marginBottom: "15px" }}>
              <label htmlFor="email">Email *</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                style={{
                  width: "100%",
                  padding: "10px",
                  boxSizing: "border-box",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                }}
              />
            </div>
            <div style={{ marginBottom: "15px" }}>
              <label htmlFor="phone">Telefono</label>
              <input
                type="tel"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                style={{
                  width: "100%",
                  padding: "10px",
                  boxSizing: "border-box",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                }}
              />
            </div>
            <div style={{ marginBottom: "15px" }}>
              <label htmlFor="message">Messaggio *</label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                required
                style={{
                  width: "100%",
                  padding: "10px",
                  boxSizing: "border-box",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  height: "150px",
                }}
              />
            </div>
            <div className="button-container" style={{ textAlign: "center" }}>
              <Button
                variant="primary"
                type="submit"
                className="m-2"
                style={{ backgroundColor: "#d090d8", borderColor: "#d090d8" }}
              >
                Invia
              </Button>
            </div>
          </form>

          {/* Contatto WhatsApp */}
          <div style={{ marginTop: "40px", textAlign: "center" }}>
            <h2 style={{ fontFamily: "Playfair Display, serif", color: "black", marginBottom: "20px" }}>
              Contattaci su WhatsApp
            </h2>
            <a
              href="https://wa.me/393403940206"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none" }}
            >
              <Button variant="success" className="m-2" style={{ backgroundColor: "#25D366", borderColor: "#25D366" }}>
                <img src={whatsappIcon} alt="WhatsApp Icon" style={{ width: "24px", marginRight: "10px" }} />
                WhatsApp
              </Button>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
